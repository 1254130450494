<template>
  <v-card v-if="instance">
    <v-toolbar
      class="mt-2"
      dense
      flat
    >
      <v-btn
        icon
        @click="$router.go(-1)"
      >
        <v-icon dense>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-breadcrumbs :items="breadcrumbs" />
      <v-spacer />
      <VButtonDialog
        :disabled="instance.isAdmin"
        @confirm="remove(instance)"
      >
        Delete
      </VButtonDialog>
    </v-toolbar>
  </v-card>
</template>

<script>

import VButtonDialog from '@/components/VButtonDialog'
import { CompanyUser } from '@/store/models'

export default {
  name: 'CompanyUserId',
  components: { VButtonDialog },
  computed: {
    breadcrumbs () {
      return [
        {
          text: 'Company Members',
          exact: true,
          to: { name: 'App-Users' }
        },
        {
          text: `${this.instance.user.firstName} ${this.instance.user.lastName}`,
          exact: true,
          to: { name: 'App-Users-id', params: { id: this.instance.id } }
        }
      ]
    },
    instance () {
      return CompanyUser.query().with('user').find(this.$route.params.id)
    }
  },
  methods: {
    async remove (data) {
      this.$router.go(-1)
      await CompanyUser.api().delete(data.id, { delete: data.id })
    }
  }
}
</script>
